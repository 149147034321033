:root {
    --bokamera-primary: #007bff;
    --bokamera-primary-active: #0069d9;
    --bokamera-success-light: #cbf2dc;
    --bokamera-success: #8eddb0;
    --bokamera-text-success: #3c9412;
    --bokamera-danger-light: #d3a6a6;
    --bokamera-danger: #ff5a44;
    --bokamera-grey-100: #f8fafc;
    --bokamera-grey-200: #f1f5f8;
    --bokamera-grey-300: #dae1e7;
    --bokamera-grey-400: #b8c2cc;
    --bokamera-grey-500: #8795a1;
    --bokamera-grey-600: #606f7b;
    --bokamera-grey-700: #3d4852;
    --bokamera-grey-800: #22292f;
    --bokamera-white: #fff;
    --bokamera-black: #000;
    --bokamera-border: 1px solid rgba(240, 237, 237, 0.5);
    --bokamera-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
        0 0 0 1px rgba(10, 10, 10, 0.02);
    --bokamera-text-color: var(--bokamera-black);
    --bokamera-bg-color: #fff;
    --bokamera-btn-bg: var(--bokamera-grey-200);
    --bokamera-btn-bg-active: var(--bokamera-grey-300);
    --bokamera-input-bg: var(--bokamera-white);
    --bokamera-input-border: var(--bokamera-grey-300);
}

body {
    background-color: #edf2f7 !important;
}

html,
body,
#bokamera-embedded-configurator {
    height: 100%;
}
