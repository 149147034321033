.color-circle {
    width: 36px;
    height: 36px;
    border: 1px solid #ccc;
    border-radius: 50%;
    transition: transform 0.2s ease-out;
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
}
.color-circle:hover {
    transform: scale(110%);
}
