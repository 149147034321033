.obfuscator {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.layer-of-blur {
    position: relative;
    filter: blur(4px);

}

.reason {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}
.reason__text {
    border: 1px solid red;
    border-radius: 4px;
    border-color: var(--bokamera-grey-300);
    background: var(--bokamera-white);
    padding: 20px;
    box-shadow: var(--bokamera-box-shadow);
    max-width: 480px;
    width: 80%;
    padding: 20px 35px;

}
