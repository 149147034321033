.app {
    display: flex;
    height: 100%;
}
.sidebar {
    flex: 1 0 400px;
    box-shadow: 4px 0 6px rgba(0, 0, 0, 0.12);
    position: relative;
    overflow-y: hidden;
}
.content {
    flex: 2 2 67%;
    overflow-y: scroll;
    height: 100%;
}
