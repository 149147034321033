.container {
    height: 100%;
    position: relative;
    overflow-y: hidden;
    background-color: white;
}
.container.dark {
    background-color: #3d4852;
}
.navbar {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--bokamera-grey-100);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
    z-index: 5;
}
.main {
    overflow-y: scroll;
    padding: 80px 20px 40px;
    height: 100%;
}
.nav-icon {
    cursor: pointer;
    color: #3d4852;
    margin: 6px;
}
.nav-icon:hover {
    opacity: 0.7;
}
:global .info.card {
    margin-bottom: 16px;
    background-color: #f8ffff;
    box-shadow: inset 0 0 0 1px #a9d5de, 0 0 0 0 transparent;
}