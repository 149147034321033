.form {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background-color: var(--bokamera-grey-100);
}
.form-body {
    padding: 1.25rem;
}
.actions {
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.12);
    padding: 20px;
}
.submit-btn {
    width: 100%;
}
.company-name {
    margin-bottom: 20px;
}
.translation {
    display: flex;
    justify-content: flex-start;
}
.translation-key,
.translation-value {
    margin-right: 0.5rem;
    flex: 1 1 50%;
}
